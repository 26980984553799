(<template>
  <div class="gender">
    <div class="gender__wrapper">
      <span class="gender-icon"
            :class="[`gender-icon--${gender}`]"></span>
      <p class="gender-name">{{ genderType || '-' }}</p>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      gender() { return this.propData?.sex; },
      genderType() {
        switch (this.gender) {
          case 'male':
            return this.$gettext('Male');
          case 'female':
            return this.$gettext('Female');
        }
      }
    }
  };
</script>
<style scoped>
.gender {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.gender__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.gender-icon {
  width: 16px;
  height: 19px;
  margin-right: 10px;
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.gender-icon--male {
  background-image: url(~@assets/imgs/salita/gender_icons/male_icon.svg);
}

.gender-icon--female {
  background-image: url(~@assets/imgs/salita/gender_icons/female_icon.svg);
}
</style>
