(<template>
  <div class="standby">
    <div class="standby-wrapper">
      <span v-if="standByPlus"
            :class="{'star-icon': standByPlus}"></span>
      <span v-else-if="standBy"
            :class="{'tick-icon': standBy}"></span>
      <p class="standby-status">{{ standByStatus }}</p>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      standBy() { return this.propData?.interpretation?.standby; },
      standByPlus() { return this.propData?.interpretation?.standbyPlus || true; },
      standByStatus() {
        if (this.standByPlus) return this.$gettext('Plus');
        else if (this.standBy) return this.$gettext('Yes');
        else return '-';
      },
    }
  };
</script>
<style scoped>
.standby {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.standby-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.tick-icon,
.star-icon {
  width: 16px;
  height: 19px;
  margin-right: 10px;
  background-image: url(~@assets/imgs/undefined_imgs/tick_icon.svg);
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.star-icon {
  background-image: url(~@assets/imgs/undefined_imgs/green_star_icon.svg);
}
</style>
