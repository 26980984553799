<template>
  <section class="gender-filter">
    <sk-multiselect :items-list="genderTypes"
                    :preselected-value="selectedGenders"
                    :default-value="placeholder"
                    @checkboxeschanged="onInputBoxChanged" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        selectedGenders: []
      };
    },
    computed: {
      genderTypes() {
        return [
          {id: '0', name: this.$gettext('Male')},
          {id: '1', name: this.$gettext('Female')}
        ];
      },
      placeholder() { return this.$gettext('Select a gender'); },

    },
    methods: {
      onInputBoxChanged(value) {
        this.selectedGenders = [...value];
        if (this.selectedGenders === '') {
          return;
        }
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('inList', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.selectedGenders = '';
        }
      }
    }
  };
</script>

<style>
.gender-filter .sk-select {
  background-color: #fff;
}

.gender-filter .sk-select__value {
  color: #333;
  font-weight: normal;
  font-size: 13px;
}

.gender-filter {
  width: 100%;
}
</style>
