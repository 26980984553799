(<template>
  <div class="interpretation-stats">
    <div v-if="statsNotZero"
         class="interpretation-stats__wrapper">
      <span v-for="(stat, index) in statsList"
            :key="index"
            class="stat"
            @mouseleave="closeTooltip"
            @mouseover="openTooltip(stat.name, $event)">
        <template v-if="stat.value != null">
          <span
            v-if="index !== 0"
            class="divider">/</span>
          {{ stat.value }}
        </template>
      </span>
    </div>
    <p v-else>-</p>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      stats() { return this.propData?.interpretation?.statistics; },
      invitedStats() { return this.stats?.invited || 0; },
      withdrawnStats() { return this.stats?.withdrawn || 0; },
      showUpStats() { return this.stats?.showedUp || 0; },
      lateStats() { return this.stats?.late || 0; },
      noShowStats() { return this.stats?.noShow || 0; },
      statsNotZero() {
        return this.invitedStats || this.withdrawnStats
          || this.showUpStats || this.lateStats || this.noShowStats;
      },
      statsList() {
        return [
          {
            name: this.$gettext('Invited'),
            value: this.invitedStats
          },
          {
            name: this.$gettext('Withdrawn'),
            value: this.withdrawnStats
          },
          {
            name: this.$gettext('Showed up'),
            value: this.showUpStats
          },
          {
            name: this.$gettext('Late'),
            value: this.lateStats
          },
          {
            name: this.$gettext('No Show'),
            value: this.noShowStats
          }
        ];
      }
    },
    methods: {
      itemPosition(event) {
        const {top, left} = helpers.getElementPosition(event.target);
        return {top: (top) + 'px', left: (left + 20) + 'px'};
      },
      openTooltip(text, event) {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: text,
            relativePosition: 'top-center'
          },
          absolutePosition: this.itemPosition(event)
        });
      },
      closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); }
    }
  };
</script>
<style scoped>
.interpretation-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.interpretation-stats__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.stat {
  cursor: pointer;
}

.divider {
  margin: 0 3px;
  color: #a7abbd;
}
</style>
